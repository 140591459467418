// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
// import users from '@src/views/user/store';
// import chat from '@src/views/AdminGeneral/chat/store/reducer';

const rootReducer = {
  auth,
  // users,
  navbar,
  layout,
  // chat,

};

export default rootReducer;
