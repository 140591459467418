// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt';

import client from '../graphql/client';

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem('userData');
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state.userData.avatar = action.payload.avatar;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem('userData', JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload[config.storageTokenKeyName]
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload[config.storageRefreshTokenKeyName]
      );
      client.resetStore();
    },
    handleUserUpdate: (state, action) => {
      state.userData.fullName = action.payload.fullName;
      state.userData.email = action.payload.email;
      state.userData.permissions = action.payload.permissions;
      state.userData.avatar = action.payload.avatar;
      localStorage.setItem('userData', JSON.stringify(action.payload));
    },
    handleLogout: (state) => {
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData');
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
      window.location.href = '/login';
      client.resetStore();
    }
  }
});

export const { handleLogin, handleLogout, handleUserUpdate } =
  authSlice.actions;

export default authSlice.reducer;
