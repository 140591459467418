import mock from './mock';
import './jwt';
import './pages/faq';
import './apps/calendar';
import './apps/userList';
import './apps/eCommerce';
import './navbar/navbarSearch';
import './cards/card-analytics';
import './cards/card-statistics';
import './autoComplete/autoComplete';
import './apps/chat';

mock.onAny().passThrough();
